<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="8">


          <div class="text-center mt-15">

            <v-icon size="100" color="red">mdi-alert</v-icon>

            <h1 class="py-5">
              Signaler un problème
            </h1>

            <p>
              Veuillez fournir autant de détails que possible afin que nous puissions mieux identifier le problème.
              Quand (heure/date) le problème est-il survenu ?
              Quel est le message d'erreur exact que vous avez reçu ?
            </p>
          </div>

          <div v-if="success">
            <v-card class="shadow rounded-lg mb-5">
              <v-card-text class="px-8 text-center">
                <v-icon size="60" color="success" class="mb-3">mdi-check-circle</v-icon>
                <p>
                  Votre rapport a été envoyé avec succès!
                </p>
              </v-card-text>
            </v-card>
          </div>

          <div v-else>


            <v-card class="shadow rounded-lg mb-5" :loading="isLoading" :disabled="isLoading">
              <v-card-text class="px-8">

                <v-alert v-if="errors" type="error" dismissible>
                  <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                  </ul>
                </v-alert>

                <v-checkbox v-for="(listError, i) in listErrors"
                            v-model="form.list_error_ids"
                            :value="listError.id"
                            hide-details
                            multiple
                            :key="i"
                            :label="listError.name"></v-checkbox>

                <v-textarea v-model="form.other"
                            class="mt-5"
                            rows="3" label="Autre ..." outlined></v-textarea>
              </v-card-text>
            </v-card>

            <v-btn block color="gifty"
                   :loading="overlay"
                   @click="sendReport"
                   :disabled="!form.other && !form.list_error_ids.length"
                   depressed
                   class="rounded-lg white--text">
              Envoyer
            </v-btn>
          </div>

        </v-col>
      </v-row>


      <div class="footer-container">
        <span>© Copyright {{ new Date().getFullYear() }}</span>
        <img :src="require('@/assets/logo2.png')" width="60" alt="gifty">
        <span>Tous droits réservés.</span>
      </div>

    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        list_error_ids: [],
        other: null,
        key: this.$route.query.key,
      },
      overlay: false,
      isLoading: false,
      listErrors: [],
      errors: null,

      success: false,

    };
  },
  methods: {
    sendReport() {
      this.overlay = true;
      this.isLoading = true;
      this.errors = null;

      axios.post(`${process.env.VUE_APP_BASE_URL}v1/errors-reported/store`, this.form)
          .then(() => {
            this.overlay = false;
            this.isLoading = false;
            this.success = true;
          })
          .catch(err => {
            this.overlay = false;
            this.isLoading = false;

            if (err.response && err.response.status === 422) {
              this.errors = Object.values(err.response.data.errors).flat();
              this.$errorMessage = "Une erreur s'est produite. Veuillez la corriger.";
            } else {
              this.$errorMessage = "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.";
            }

            console.log(err);
          });
    },
    getErrors() {
      this.isLoading = true
      axios.get(process.env.VUE_APP_BASE_URL + 'v1/errors-reported/list-errors/public').then(res => {
        this.listErrors = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getErrors();
  },
};
</script>

<style scoped>
.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  text-align: center;
}

.footer-container span {
  margin: 0 10px;
}
</style>